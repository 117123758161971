import { Col } from 'react-bootstrap';

export const ProjectCard = ({ title, description, imgUrl, type }) => {
    const mdSize = type === 'mobile' ? 3 : 4;
    return (
        <Col size={12} sm={6} md={mdSize}>
            <div className="d-flex justify-content-center">
                <div className="proj-imgbx" style={type === 'mobile' ? { width: '200px' } : {}}>
                    <img className={title === 'Portfolio project' ? 'img-portfolio' : ''} src={imgUrl} width={type === 'mobile' ? '200px' : ''} alt="project-img" />
                    <div className="proj-txtx">
                        <h4>{title}</h4>
                        <span>{description}</span>
                    </div>
                </div>
            </div>
        </Col>
    );
};
