import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
export const CircularProgress = ({ percentage }) => {
    return (
        <div style={{ width: 160, height: 160 }}>
            <svg style={{ height: 0, width: 0 }}>
                <defs>
                    <linearGradient id="gradient-circular-bar" gradientTransform={'rotate(90)'}>
                        <stop offset="0%" stopColor={'#6331AC'} />
                        <stop offset="100%" stopColor={'#9F3584'} />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                strokeWidth="10"
                styles={{ path: { stroke: `url(#${'gradient-circular-bar'})`, height: '100%' }, text: { fill: '#ffffff', fontWeight: '600' }, trail: { stroke: 'transparent' } }}
            />
        </div>
    );
};
