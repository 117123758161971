import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: {
                    fhome: 'Home',
                    fskills: 'Skills',
                    fprojects: 'Projects',
                    fconnect: `Let's connect`,
                    fim: `I'm `,
                    fDeveloper: ' Developer',
                    fDescription: ` Experienced Software Developer skilled in designing, testing, and maintaining software systems. Proficient in multiple platforms, languages, embedded
                            systems, and cloud technologies.`,
                    fWelcomePortfolio: 'Welcome to my portfolio',
                    fSkillsOverview: `I specialize in Angular and React for dynamic frontend applications, Node.js and C# for robust backend solutions. As a cloud engineer, I design and manage scalable cloud infrastructures, and my AI engineering skills enable me to develop intelligent systems. My expertise spans various modern technologies, allowing me to tackle diverse projects effectively.`,
                    skill1: 'Node JS',
                    skill2: 'Angular',
                    skill3: 'React',
                    skill4: 'AWS',
                    skill5: '.NET',
                    skill6: 'Java',
                    skill7: 'GCP',
                    skill8: 'Python',
                    projectAndCourses: 'Projects & Courses',
                    projectsParagraph: `Some of my best projects include those created for both practice and freelance development. These projects span various industries, showcasing my versatility in web applications, e-commerce platforms, and content management systems. They demonstrate my technical skills, ability to manage project requirements, and commitment to delivering high-quality solutions.`,
                    web: 'Web',
                    mobile: 'Mobile',
                    courses: 'Courses',
                },
            },
            es: {
                translation: {
                    fhome: 'Home',
                    fskills: 'Habilidades',
                    fprojects: 'Proyectos',
                    fconnect: `contáctame`,
                    fim: `Soy `,
                    fDeveloper: ` Developer`,
                    fDescription: ` Desarrollador de software con experiencia en diseño, pruebas y mantenimiento de sistemas de software. Competente en múltiples plataformas, lenguajes, sistemas
                            embebidos y tecnologías en la nube.`,
                    fWelcomePortfolio: 'Bienvenido a mi portafolio',
                    fSkillsOverview: `Me especializo en distintos frameworks frontend y backend. Como ingeniero de la nube, diseño y gestiono infraestructuras escalables, y mis habilidades en ingeniería de IA me permiten desarrollar sistemas inteligentes. Mi experiencia abarca diversas tecnologías modernas, permitiéndome abordar proyectos variados de manera efectiva:`,
                    skill1: 'Node JS',
                    skill2: 'Angular',
                    skill3: 'React',
                    skill4: 'AWS',
                    skill5: '.NET',
                    skill6: 'Java',
                    skill7: 'GCP',
                    skill8: 'Python',
                    projectAndCourses: 'Proyectos y cursos',
                    projectsParagraph: `Algunos de mis mejores proyectos incluyen aquellos creados tanto para práctica como para desarrollos freelance. Estos proyectos abarcan diversas industrias, mostrando mi versatilidad en aplicaciones web, plataformas de comercio electrónico y sistemas de gestión de contenido. Demuestran mis habilidades técnicas, mi capacidad para gestionar los requisitos del proyecto y mi compromiso con la entrega de soluciones de alta calidad.`,
                    web: 'Web',
                    mobile: 'Móvil',
                    courses: 'Cursos',
                },
            },
        },
        lng: 'es', // if you're using a language detector, do not define the lng option
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
