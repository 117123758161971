import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import headerImg from '../assets/img/astronaut.png';
import yo from '../assets/img/yo.jpeg';

import 'animate.css';

export const Banner = () => {
    const { t } = useTranslation();
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ['Web', 'Fullstack', 'Cloud', 'Data', 'Engineer'];
    const [text, setText] = useState('');
    const [isHovered, setIsHovered] = useState('animate__backInDown');

    const period = 1000;
    const [delta, setDelta] = useState(300 - Math.random() * 100);

    // eslint-disable-next-line
    useEffect(() => {
        // eslint-disable-next-line
        const ticker = setInterval(() => {
            // eslint-disable-next-line
            tick();
            // eslint-disable-next-line
        }, delta);
        // eslint-disable-next-line
        return () => clearInterval(ticker);
    }, [text]);

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if (isDeleting) {
            setDelta((prevDelta) => prevDelta / 2);
        }

        if (!isDeleting && updatedText === fullText) {
            setIsDeleting(true);
            setDelta(period);
        } else if (isDeleting && updatedText === '') {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(500);
        }
    };

    const handleHover = () => {
        setIsHovered('animate__tada animate__fast');
        // const animated = document.getElementById('img-yo');
        // console.log(animated);
        // animated.addEventListener('animationend', () => {
        //     setIsHovered('');
        // });
    };
    const handleLeave = () => {
        setIsHovered('');
    };

    let classImg = `avatar animate__animated ${isHovered}`;

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={3} onMouseEnter={handleHover} onMouseLeave={handleLeave}>
                        <div className="d-flex flex-column align-items-center">
                            <img className={classImg} id="img-yo" src={yo} alt="image-own" />
                            <span className="tagline"> {t('fWelcomePortfolio')}</span>
                        </div>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <h1>
                            {t('fim')}
                            <span className="wrap">{text}</span>
                            {t('fDeveloper')}
                        </h1>
                        <p> {t('fDescription')}</p>
                        <button className="animate__animated animate__bounce animate__delay-1s" onClick={() => document.getElementById('linkedin-page').click()}>
                            {t('fconnect')} <ArrowRightCircle size={25}></ArrowRightCircle>{' '}
                        </button>
                    </Col>

                    <Col className="hero-image-container" xs={12} md={6} xl={4}>
                        <img width={500} src={headerImg} alt="header-img" />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
