import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';

import rickApp from '../assets/img/projects/rick.gif';
import svelteApp from '../assets/img/projects/svlete.gif';
import PortfolioOld from '../assets/img/projects/portfolio_new.gif';

import speedTestapp from '../assets/img/projects/ionic.gif';
import coinApp from '../assets/img/projects/coin_app.gif';
import QuizApp from '../assets/img/projects/quiz.gif';

import angularApp from '../assets/img/courses/angular.png';
import dockerApp from '../assets/img/courses/docker.png';
import bigDataMlApp from '../assets/img/courses/bigdata_ml.png';

import { useTranslation } from 'react-i18next';
import 'animate.css';

export const Projects = () => {
    const { t } = useTranslation();
    const courses = [
        {
            title: 'Angular Development',
            description: 'Course full path',
            imgUrl: angularApp,
        },
        {
            title: 'Docker',
            description: 'Course',
            imgUrl: dockerApp,
        },
        {
            title: 'Big Data & ML',
            description: 'Course',
            imgUrl: bigDataMlApp,
        },
    ];

    const projects = [
        {
            title: 'Personal API project',
            description: 'Design & Development',
            imgUrl: rickApp,
            type: 'web',
        },
        {
            title: 'Instagram clone',
            description: 'Design & Development',
            imgUrl: svelteApp,
            type: 'web',
        },
        {
            title: 'Portfolio project',
            description: 'Design & Development',
            imgUrl: PortfolioOld,
            type: 'web',
        },
        {
            title: 'Speed test App',
            description: 'Design & Development freelance',
            imgUrl: speedTestapp,
            type: 'mobile',
        },

        {
            title: 'Coin App trading statistics',
            description: 'Design & Development',
            imgUrl: coinApp,
            type: 'mobile',
        },
        {
            title: 'Quiz app',
            description: 'Design & Development',
            imgUrl: QuizApp,
            type: 'mobile',
        },
    ];

    return (
        <section className="project" id="projects">
            <Container>
                <Row>
                    <Col size={12}>
                        {/* <TrackVisibility>
                            {({ isVisible }) => ( */}
                        <div>
                            <h2>{t('projectAndCourses')}</h2>
                            <p>{t('projectsParagraph')}</p>

                            <Tab.Container id="projects-tabs" defaultActiveKey="first">
                                <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">{t('web')}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">{t('mobile')}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">{t('courses')}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content id="slideInUp">
                                    <Tab.Pane eventKey="first">
                                        <Row>
                                            {projects
                                                .filter((e) => e.type === 'web')
                                                .map((project, index) => {
                                                    return <ProjectCard key={index} {...project} />;
                                                })}
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Row className="justify-content-center">
                                            {projects
                                                .filter((e) => e.type === 'mobile')
                                                .map((project, index) => {
                                                    return <ProjectCard key={index} {...project} />;
                                                })}
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <Row className="justify-content-center">
                                            {courses.map((project, index) => {
                                                return <ProjectCard key={index} {...project} />;
                                            })}
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                        {/* )}
                        </TrackVisibility> */}
                    </Col>
                </Row>
            </Container>
            {/* <img className="background-image-right" src={colorSharp2}></img> */}
        </section>
    );
};
