import { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from '../assets/img/color-sharp.png';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from './CircularProgress';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';

export const Skills = () => {
    const { t } = useTranslation();

    const [init, setInit] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    return (
        init && (
            <>
                <Particles
                    id="tsparticles"
                    particlesLoaded={particlesLoaded}
                    options={{
                        background: {
                            color: {
                                value: 'trasparent',
                            },
                        },
                        fpsLimit: 120,
                        interactivity: {
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: 'push',
                                },
                                onHover: {
                                    enable: true,
                                    mode: 'repulse',
                                },
                                resize: true,
                            },
                            modes: {
                                push: {
                                    quantity: 4,
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: '#ffffff',
                            },
                            links: {
                                color: '#ffffff',
                                distance: 150,
                                enable: true,
                                opacity: 0.5,
                                width: 1,
                            },
                            move: {
                                direction: 'none',
                                enable: true,
                                outModes: {
                                    default: 'bounce',
                                },
                                random: false,
                                speed: 2,
                                straight: false,
                            },
                            number: {
                                density: {
                                    enable: true,
                                    area: 800,
                                },
                                value: 80,
                            },
                            opacity: {
                                value: 0.5,
                            },
                            shape: {
                                type: 'circle',
                            },
                            size: {
                                value: { min: 1, max: 5 },
                            },
                        },
                        detectRetina: true,
                    }}
                />
                <section className="skill" id="skills">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="skill-bx wow zoomIn">
                                    <h2> {t('fskills')}</h2>
                                    <p> {t('fSkillsOverview')}</p>
                                    <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                                        <div className="item">
                                            <div className="circular-container">
                                                <CircularProgress percentage="97"> </CircularProgress>
                                            </div>
                                            <h5>{t('skill1')}</h5>
                                        </div>
                                        <div className="item">
                                            <div className="circular-container">
                                                <CircularProgress percentage="94"></CircularProgress>
                                            </div>
                                            <h5>{t('skill2')}</h5>
                                        </div>
                                        <div className="item">
                                            <div className="circular-container">
                                                <CircularProgress percentage="85"></CircularProgress>
                                            </div>
                                            <h5>{t('skill3')}</h5>
                                        </div>
                                        <div className="item">
                                            <div className="circular-container">
                                                <CircularProgress percentage="80"></CircularProgress>
                                            </div>
                                            <h5>{t('skill4')}</h5>
                                        </div>
                                        <div className="item">
                                            <div className="circular-container">
                                                <CircularProgress percentage="82"></CircularProgress>
                                            </div>
                                            <h5>{t('skill5')}</h5>
                                        </div>
                                        <div className="item">
                                            <div className="circular-container">
                                                <CircularProgress percentage="85"></CircularProgress>
                                            </div>
                                            <h5>{t('skill6')}</h5>
                                        </div>
                                        <div className="item">
                                            <div className="circular-container">
                                                <CircularProgress percentage="82"></CircularProgress>
                                            </div>
                                            <h5>{t('skill7')}</h5>
                                        </div>
                                        <div className="item">
                                            <div className="circular-container">
                                                <CircularProgress percentage="92"></CircularProgress>
                                            </div>
                                            <h5>{t('skill8')}</h5>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className="background-image-left" src={colorSharp} alt="back-img" />
                </section>
            </>
        )
    );
};
